<template>
  <div class="container-fluid text-white p-0" id="footer">
    <div class="row w-100 text-start footer-padding">
      <div class="col-12 col-sm-3 p-3">
        <h4 class="mb-4">
          <i class="fa fa-map-marker-alt text-primary me-3"></i>
          {{ data.currentLanguage.footer.contacts[0].title }}
        </h4>
        <p class="mb-1 ps-0">
          Rua Joaquim Nabuco, 2197 Sala 302 - Centro São José dos Pinhais - PR - Brasil.
        </p><a href="tel:+554130814404" class="fw-bold text-primary">
          +55 (41) 3081-4404
        </a>
      </div>
      <div class="col-12 col-sm-3 p-3">
        <h4 class="mb-4">
          <i class="fa fa-map-marker-alt text-primary me-3"></i>
          {{ data.currentLanguage.footer.contacts[1].title }}
        </h4>
        <p class="mb-1 ps-0">
          Av. General Edson Ramalho 100 Sala 512 - Ed. Empresarial Tambaú 58038-100 | Manaíra, João Pessoa - PB - Brasil.
        </p><a href="tel:+554130814404" class="fw-bold text-primary">
          +55 (83) 3035-1704
        </a> | <a href="tel:+554130814404" class="fw-bold text-primary">
          +55 (83) 98156-7375
        </a>
      </div>
      <div class="col-12 col-sm-3 p-3">
        <h4 class="mb-4">
          <i class="fa fa-map-marker-alt text-primary me-3"></i>
          {{ data.currentLanguage.footer.contacts[2].title }}
        </h4>
        <p class="mb-1 ps-0">
          Lisboa - Portugal
        </p>
        <a>comercial@toolmaker.com.br</a>
        <a href="tel:+554130814404" class="fw-bold text-primary">
          +351 961 010 646
        </a>
      </div>
      <div class="col-12 col-sm-3 p-3 text-center">
        <figure>
          <img src="/assets/footer/a.png">
        </figure>
      </div>
    </div>
    <div class="bg-primary p-2">
      <small>Todos os direitos reservados.</small>
    </div>
  </div>
</template>

<script setup>

import { defineComponent, reactive, computed } from 'vue'
import { useWebsiteStore } from '@/store/website/website'

defineComponent({ name: 'Footer' })

const websiteStore = useWebsiteStore();

const data = reactive({
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  )
})

</script>

<style scoped>
.container-fluid {
  background-color: #353535
}

.footer-padding {
  padding: 100px 80px;
}

@media only screen and (max-width: 600px) {
  .footer-padding {
    padding: 50px 20px;
  }
}
</style>
