<template>
  <div class="container-fluid bg-white p-4 p-md-5">
    <div class="container">
      <h1 class="">
        {{ data.currentLanguage.ourCustomers.title }}
      </h1>
      <div class="row pb-5">
        <div class="">
          <figure>
            <img src="/assets/customers/customers1.png" width="">
          </figure>
        </div>
        <div class="mt-5">
          <figure>
            <img src="/assets/customers/customers2.png" width="">
          </figure>
        </div>
        <!-- <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/stellantis.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/renault.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/magna.jpg">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/aethra.png" height="500">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/cma.jpeg">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/comau.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/conventos.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/gaspec.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/gestamp.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/hame.jpeg">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/injetaq.jpeg">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/tqm.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/trdi.png">
          </figure>
        </div>
        <div class="col-6 col-sm-3 pt-4 pb-4 mt-5">
          <figure>
            <img src="/assets/customers/usiminas.png">
          </figure>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>

import { defineComponent, reactive, computed } from 'vue'
import { useWebsiteStore } from '@/store/website/website'

defineComponent({ name: 'Customers' })

const websiteStore = useWebsiteStore();

const data = reactive({
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  )
})

</script>

<style scoped>
/* figure img {
  max-width: 100%;
  max-height: 80px;
} */

.container-fluid {
  background-color: #f5f5f5;
}

@media only screen and (max-width: 600px) {
  figure img {
    max-width: 100%;
  }
}
</style>
