import { defineStore } from 'pinia'

const useWebsiteStore = defineStore('website', {
  state: () => ({
    currentLanguage: {},
    currentTheme: 'light-theme'
  }),
  actions: {
    setCurrentLanguage (currentLanguage) {
      this.currentLanguage = currentLanguage
    },
    setCurrentTheme (currentTheme) {
      this.currentTheme = currentTheme
    }
  }
})

export { useWebsiteStore }
