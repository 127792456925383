<template>
  <div id="app">
    <router-view :class="currentTheme"></router-view>
  </div>
</template>

<script setup>

import { useWebsiteStore } from '@/store/website/website'
import { defineComponent, computed, reactive } from "vue";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import '@/theme/core.css'

defineComponent({ name: 'App' })

const websiteStore = useWebsiteStore();

const data = reactive({
  currentTheme: computed(() =>
    websiteStore.currentTheme
  )
})

</script>
