<template>
  <div class="container-fluid p-0">
    <div class="row w-100 text-start text-white ps-sm-10 p-0 m-0">
      <div class="col-12 col-sm-6 p-3 pt-md-5 pb-md-5 ps-md-5">
        <span class="d-block mt-5">
          {{ data.currentLanguage.specialists.title1 }}
        </span>
        <h1 class="text-white ms-0 ps-0 mt-0 pt-0 pb-3 mb-4 border-bottom-primary">
          {{ data.currentLanguage.specialists.title2 }}
        </h1>
        <div class="col-12">
          <small class="text-vertical d-inline-block fw-bold me-0 me-sm-0 col-1">
            {{ data.currentLanguage.specialists.vertical1 }}
          </small>
          <ul class="list-unstyled d-inline-block col-11 ps-2">
            <li class="d-block pt-1 pb-1" v-for="index of [0, 1, 2, 3]" :key="index">
              <span class="d-inline-block">
                <i class="d-inline-block fa fa-chevron-circle-right text-primary me-2"></i>
                {{ data.currentLanguage.specialists.specialists[index] }}
              </span>
            </li>
          </ul>
        </div>
        <div class="col-12">
          <small class="text-vertical d-inline-block fw-bold me-0 me-sm-0 col-1">
            {{ data.currentLanguage.specialists.vertical2 }}
          </small>
          <ul class="list-unstyled d-inline-block col-11 ps-2">
            <li class="d-block pt-1 pb-1" v-for="index of [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]" :key="index">
              <span class="d-inline-block">
                <i class="d-inline-block fa fa-chevron-circle-right text-primary me-2"></i>
                {{ data.currentLanguage.specialists.specialists[index] }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-sm-6 our-team-bg text-center pt-5">
        <span>
          {{ data.currentLanguage.specialists.callToAction.text1 }}
        </span>
        <h1>
          {{ data.currentLanguage.specialists.callToAction.text2 }}
        </h1><a class="btn btn-primary rounded-pill" href="/assets/docs/toolmaker-perfil-tecnico.pdf" target="_blank">
          {{ data.currentLanguage.specialists.callToAction.text3 }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>

import { defineComponent, reactive, computed } from 'vue'
import { useWebsiteStore } from '@/store/website/website'

defineComponent({ name: 'Specialists' })

const websiteStore = useWebsiteStore();

const data = reactive({
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  )
})

</script>

<style scoped>
.container-fluid {
  background-color: #626161;
}

.our-team-bg {
  height: 800px;
  background: url(/assets/specialists/our-team-bg.png);
  background-size: cover;
  padding-top: 11rem !important;
}

.text-vertical {
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(-180deg);
}
</style>
