<template>
  <div class="container">
    <div class="row pb-5">
      <div class="col-12 col-sm-3 pt-4 pb-4 mt-5">
        <h3 style="text-align:left">
          <b>{{ data.currentLanguage.mission.mission.title }}</b>
        </h3>
        <p style="text-align:left;padding-top:10px">
          {{ data.currentLanguage.mission.mission.text }}
        </p>
      </div>
      <div class="col-12 col-sm-3 pt-4 pb-4 mt-5">
        <h3 style="text-align:left">
          <b>{{ data.currentLanguage.mission.vision.title }}</b>
        </h3>
        <p style="text-align:left;padding-top:10px">
          {{ data.currentLanguage.mission.vision.text }}
        </p>
      </div>
      <div class="col-12 col-sm-3 pt-4 pb-4 mt-5">
        <h3 style="text-align:left">
          <b>{{ data.currentLanguage.mission.values.title }}</b>
        </h3>
        <p style="text-align:left;padding-top:10px">
          {{ data.currentLanguage.mission.values.text }}
        </p>
      </div>
      <div class="col-12 col-sm-3 pt-4 pb-4 mt-5">
        <h3 style="text-align:left">
          <b>{{ data.currentLanguage.mission.qualityPolicy.title }}</b>
        </h3>
        <p style="text-align:left;padding-top:10px">
          {{ data.currentLanguage.mission.qualityPolicy.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>

import { defineComponent, reactive, computed } from 'vue'
import { useWebsiteStore } from '@/store/website/website'

defineComponent({ name: 'Mission' })
const websiteStore = useWebsiteStore();

const data = reactive({
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  )
})

</script>

<style scoped></style>
