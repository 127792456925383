<template>
  <div class="container-fluid pt-5 pb-5" id="about-us">
    <div class="row">
      <div class="col-12 col-sm-5 p-2 p-sm-5">
        <figure class="mb-2 mb-sm-5">
          <img src="/assets/about-us/about-us.png" height="300">
        </figure>
      </div>
      <div class="col-12 col-sm-7 p-4 p-sm-5 text-start">
        <h1 class="d-inline-block pb-3 pb-md-5 mb-sm-0 text-center text-sm-start">
          {{ data.currentLanguage.aboutUs.title }}
        </h1>
        <div class="pe-sm-5">
          <p>
            {{ data.currentLanguage.aboutUs.text1 }}
          </p>
          <p>
            {{ data.currentLanguage.aboutUs.text2 }}
          </p>
          <p>
            {{ data.currentLanguage.aboutUs.text3 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>


import { useWebsiteStore } from '@/store/website/website'
import { isMobile } from '@/utils/responsive'
import { defineComponent, defineEmits, computed, reactive, toRefs } from 'vue'
import "flag-icons/css/flag-icons.min.css";

defineComponent({ name: 'AboutUs' })

const websiteStore = useWebsiteStore();
const data = reactive({
  currentTheme: computed(() =>
    websiteStore.currentTheme
  ),
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  ),
  isMobile
})


</script>

<style scoped>
h1 {
  color: #444
}
</style>
