<template>
  <div class="container-fluid">

    <carousel :items-to-show="1" :wrapAround="true">
      <slide :index="0">
        <figure>
          <img :src="data.currentLanguage.intro.img1" />
        </figure>
      </slide>
      <slide :index="1">
        <figure>
          <img :src="data.currentLanguage.intro.img2" />
        </figure>
      </slide>
      <slide :index="2">
        <figure>
          <img :src="data.currentLanguage.intro.img3" />
        </figure>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>

  </div>
</template>

<script setup>

import { defineComponent, defineExpose, reactive, computed } from 'vue'
import { useWebsiteStore } from '@/store/website/website'
import { isMobile } from '@/utils/responsive'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

defineComponent({ name: 'Intro' })

const websiteStore = useWebsiteStore();
const data = reactive({
  currentTheme: computed(() =>
    websiteStore.currentTheme
  ),
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  ),
  isMobile
})

defineExpose({
  data
})

</script>

<style scoped>
img {
  width: 100%;
}

p {
  font-family: 'Poppins', sans-serif;
}


.light-theme p {
  color: #333;
}

@media only screen and (max-width: 600px) {}
</style>
