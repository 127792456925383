<template>
  <div class="container-fluid text-start ps-3 ps-md-5" id="expertises">
    <div class="row">
      <div class="col-12 col-sm-7 pt-5 pb-5">
        <h1 class="text-white mt-5 mb-5 pt-5">
          {{ data.currentLanguage.expertises.title }}
        </h1>
        <div class="row">
          <div class="col-12 col-sm-6">
            <ul class="list-unstyled">
              <li class="d-inline-flex pt-2 pb-2 pt-md-3 pb-md-3" v-for="index of [0, 1, 2, 3]" :key="index">
                <i class="d-inline-block fa fa-chevron-circle-right text-orange me-2"></i><span class="d-inline-block">
                  {{ data.currentLanguage.expertises.expertises[index] }}
                </span>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-6">
            <ul class="list-unstyled">
              <li class="d-inline-flex pt-2 pb-2 pt-md-3 pb-md-3" v-for="index of [4, 5, 6, 7, 8]" :key="index">
                <i class="d-inline-block fa fa-chevron-circle-right text-orange me-2"></i><span class="d-inline-block">
                  {{ data.currentLanguage.expertises.expertises[index] }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-5 expertises-bg"></div>
    </div>
  </div>
</template>

<script setup>

import '@fortawesome/fontawesome-free/css/all.css'
import { useWebsiteStore } from '@/store/website/website'
import { isMobile } from '@/utils/responsive'
import { defineComponent, computed, reactive } from 'vue'

defineComponent({ name: 'Expertises' })

const websiteStore = useWebsiteStore();
const data = reactive({
  currentTheme: computed(() =>
    websiteStore.currentTheme
  ),
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  ),
  isMobile
})

</script>

<style scoped>
.container-fluid {
  background-color: #626161;
  margin-top: -105px;
}

.expertises-bg {
  height: 700px;
  background: url(/assets/expertises/expertises-bg.png);
  background-size: cover;
}

ul li {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
}
</style>
