import $ from 'jquery'
const MOBILE_BREAKPOINT = 600

function isMobile () {
  if ($('body').innerWidth() <= MOBILE_BREAKPOINT) {
    return true
  } else {
    return false
  }
}

export { isMobile }
