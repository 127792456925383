<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <figure class="pt-2 pb-2 ps-3 pe-3">
        <img src="/assets/logo.png" height="40">
      </figure>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 ms-md-4">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#home">
              {{ data.currentLanguage.header.home }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#expertises">
              {{ data.currentLanguage.header.expertises }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about-us">
              {{ data.currentLanguage.header.aboutUs }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#footer">
              {{ data.currentLanguage.header.contactUs }}
            </a>
          </li>
        </ul>
        <div>
          <a class="me-5" @click="share()">
            <i class="fas fa-share-alt"></i>
          </a>
          <div class="dropdown d-inline-block">
            <a class="me-5" data-bs-toggle="dropdown">

              <span class="fi fi-br me-1" v-if="data.flag == 'pt-br'"></span>
              <span class="fi fi-us me-1" v-if="data.flag == 'en'"></span>
              <span class="fi fi-es me-1" v-if="data.flag == 'es'"></span>
              <span class="fi fi-fr me-1" v-if="data.flag == 'fr'"></span>
              <span class="fi fi-de me-1" v-if="data.flag == 'de'"></span>

              <small class="" v-if="data.flag == 'pt-br'">
                Português
              </small>
              <small class="" v-if="data.flag == 'en'">
                English
              </small>
              <small class="" v-if="data.flag == 'es'">
                Español
              </small>
              <small class="" v-if="data.flag == 'fr'">
                Français
              </small>
              <small class="" v-if="data.flag == 'de'">
                Deutsch
              </small>

              <!-- <i class="fas fa-globe-americas"></i> -->
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" @click="setCurrentLanguage('pt-br')">
                  <span class="fi fi-br me-2"></span>
                  <small>Português brasileiro</small>
                </a></li>
              <li><a class="dropdown-item" @click="setCurrentLanguage('en')">
                  <span class="fi fi-us me-2"></span>
                  <small>English</small>
                </a></li>
              <!-- <li><a class="dropdown-item" @click="setCurrentLanguage('es')">
              <span class="fi fi-es me-2"></span>
              <small>Español</small>
            </a></li> -->
              <li><a class="dropdown-item" @click="setCurrentLanguage('fr')">
                  <span class="fi fi-fr me-2"></span>
                  <small>Français</small>
                </a></li>
              <li><a class="dropdown-item" @click="setCurrentLanguage('de')">
                  <span class="fi fi-de me-2"></span>
                  <small>Deutsch</small>
                </a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>

import { useWebsiteStore } from '@/store/website/website'
import { isMobile } from '@/utils/responsive'
import { defineComponent, defineEmits, computed, reactive, toRefs } from 'vue'
import "flag-icons/css/flag-icons.min.css";

defineComponent({ name: 'Header' })
const emit = defineEmits(['setCurrentTheme', 'setCurrentLanguage'])

const websiteStore = useWebsiteStore();
const data = reactive({
  flag: 'pt-br',
  currentTheme: computed(() =>
    websiteStore.currentTheme
  ),
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  ),
  isMobile
})

function setCurrentTheme (theme) {
  emit('setCurrentTheme', theme)
}
function setCurrentLanguage (language) {
  data.flag = language
  emit('setCurrentLanguage', language)
}

function share () {
  Navigator.share({
    title: 'Toolmaker Service Brazil',
    text: data.currentLanguage.header.share,
    url: 'https://toolmaker.com.br'
  })
}


</script>

<style scoped>
a {
  color: #555 !important;
  transition: .2s;
}

a:hover {
  color: #BC4C37 !important;
  transition: .2s;
  cursor: pointer;
}

.dark-theme header a {
  color: #cfcfcf;
  font-size: 1.2rem;
  transition: .2s;
}

.dark-theme header a:hover {
  color: #fff;
  transition: .2s;
  cursor: pointer;
}

.dropdown-item {
  font-size: 1rem;
  color: #333;
}

.dropdown-item:hover {
  color: #333;
  cursor: pointer;
}

.dropdown-item:active {
  background-color: inherit;
}

.dropdown-menu li a {
  color: #333
}

.dropdown-menu li a:hover {
  color: #333
}

@media only screen and (max-width: 600px) {}
</style>
