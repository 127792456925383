<template>
  <div class="container-fluid text-center" :key="currentLanguage">

    <Header @setCurrentLanguage="setCurrentLanguage($event)" @setCurrentTheme="setCurrentTheme($event)"></Header>
    <Intro></Intro>
    <Expertises></Expertises>
    <AboutUs></AboutUs>
    <Specialists></Specialists>
    <Customers></Customers>
    <Mission></Mission>
    <Footer></Footer>

  </div>
</template>

<script setup>

import $ from 'jquery';
import Header from '@/components/Header.vue';
import Intro from '@/components/Intro.vue';
import Expertises from '@/components/Expertises.vue';
import AboutUs from '@/components/AboutUs.vue';
import Specialists from '@/components/Specialists.vue';
import Customers from '@/components/Customers.vue';
import Mission from '@/components/Mission.vue';
import Footer from '@/components/Footer.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import { defineComponent, computed, reactive, toRefs, onBeforeMount, onMounted } from "vue";
import { useWebsiteStore } from '@/store/website/website'

import ptBr from '@/locale/pt-br.json'
import en from '@/locale/en.json'
import es from '@/locale/es.json'
import fr from '@/locale/fr.json'
import de from '@/locale/de.json'

defineComponent({ name: 'Home' })

const websiteStore = useWebsiteStore();

const data = reactive({
  languages: {
    "pt-br": ptBr,
    "en": en,
    "es": es,
    "fr": fr,
    "de": de,
  },
  currentTheme: computed(() =>
    websiteStore.currentTheme
  ),
  currentLanguage: computed(() =>
    websiteStore.currentLanguage
  )
})

onBeforeMount(() => {
  $('title').html('Toolmaker Service Brazil');
  setCurrentLanguage('pt-br');
  chooseThemeFromTime();
})

function setCurrentLanguage (language) {
  console.log(language)
  document.lang = language;
  websiteStore.setCurrentLanguage(data.languages[language]);
}

function chooseThemeFromTime () {

  setCurrentTheme('light-theme')

  // const date = new Date();
  // var hours = parseInt(date.getHours());

  // if(hours >= 8 && hours <= 18){
  // 	this.setCurrentTheme('light-theme')
  // }
  // else{
  // 	this.setCurrentTheme('dark-theme')
  // }


}

function setCurrentTheme (theme) {
  websiteStore.setCurrentTheme(theme);
}

</script>

<style scoped>
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
</style>